import React from "react";

import Carousel from 'react-bootstrap/Carousel';

// import data from '../data/veneto-village.json';

import imagenSlide01 from "../assets/statics/veneto-village/veneto-village-01.jpg";
import imagenSlide02 from "../assets/statics/veneto-village/veneto-village-02.jpg";
import imagenSlide03 from "../assets/statics/veneto-village/veneto-village-03.jpg";
// import imagenSlide04 from "../assets/statics/veneto-village/veneto-village-04.jpg";
import imagenSlide05 from "../assets/statics/veneto-village/veneto-village-05.jpg";
import imagenSlide06 from "../assets/statics/veneto-village/veneto-village-06.jpg";
import imagenSlide07 from "../assets/statics/veneto-village/veneto-village-07.jpg";
import imagenSlide08 from "../assets/statics/veneto-village/veneto-village-08.jpg";
import imagenSlide09 from "../assets/statics/veneto-village/veneto-village-09.jpg";
import imagenSlide10 from "../assets/statics/veneto-village/veneto-village-10.jpg";
import imagenSlide11 from "../assets/statics/veneto-village/veneto-village-11.jpg";
import imagenSlide12 from "../assets/statics/veneto-village/veneto-village-12.jpg";
import imagenSlide13 from "../assets/statics/veneto-village/veneto-village-13.jpg";
import imagenSlide14 from "../assets/statics/veneto-village/veneto-village-14.jpg";
import imagenSlide15 from "../assets/statics/veneto-village/veneto-village-15.jpg";
import imagenSlide16 from "../assets/statics/veneto-village/veneto-village-16.jpg";
import imagenSlide17 from "../assets/statics/veneto-village/veneto-village-17.jpg";
import imagenSlide18 from "../assets/statics/veneto-village/veneto-village-18.jpg";
import imagenSlide19 from "../assets/statics/veneto-village/veneto-village-19.jpg";
import imagenSlide20 from "../assets/statics/veneto-village/veneto-village-20.jpg";
import imagenSlide21 from "../assets/statics/veneto-village/veneto-village-21.jpg";
import imagenSlide22 from "../assets/statics/veneto-village/veneto-village-22.jpg";
import imagenSlide23 from "../assets/statics/veneto-village/veneto-village-23.jpg";
import imagenSlide24 from "../assets/statics/veneto-village/veneto-village-24.jpg";
import imagenSlide25 from "../assets/statics/veneto-village/veneto-village-25.jpg";
import imagenSlide26 from "../assets/statics/veneto-village/veneto-village-26.jpg";
import imagenSlide27 from "../assets/statics/veneto-village/veneto-village-27.jpg";
import imagenSlide28 from "../assets/statics/veneto-village/veneto-village-28.jpg";
import imagenSlide29 from "../assets/statics/veneto-village/veneto-village-29.jpg";
import imagenSlide30 from "../assets/statics/veneto-village/veneto-village-30.jpg";
import imagenSlide31 from "../assets/statics/veneto-village/veneto-village-31.jpg";


const Product1 = (props) => (
    <div className="product">
        
        <div className="container">

            <div className="summary">
                <h2 className="mb-4"><span>Veneto Village </span><small>Villa Parque Síquiman</small></h2>
                <p>En Villa Parque Siquiman, a 10 minutos de Villa Carlos Paz, se encuentra Veneto Village, un condominio con vista al lago y a las sierras. Allí, a los pies del lago San Roque, se alzan 4 torres con hermosos departamentos.</p>
                <p>Vistas insuperables, <strong>120 metros de costa exclusiva para deportes náuticos</strong>, club house, un área deportiva de lujo: canchas de fútbol, tenis, paddle, básquet y bochas, y los mejores amenities con <strong>piletas exteriores e interiores, spá y sauna.</strong></p> 
                <p>Combinamos confort, seguridad y tranquilidad, en un entorno único y singular.</p>
            </div>

            <div className="video mb-4">
                <div className="embed-responsive embed-responsive-21by9">
                    <iframe className="embed-responsive-item" title="algo" src="https://www.youtube.com/embed/oQTQVJqRv5k?rel=0&amp;showinfo=0" loading="lazy" ></iframe>
                </div>
            </div>
            
            <div className="summary">
                <h3 className="mt-2 mb-4">Características distintivas</h3>
                <ul>
                    <li><span></span>Guardería náutica</li>
                    <li><span></span>Área deportiva</li>
                    <li><span></span>Piscinas exteriores con vista a las montañas</li>
                    <li><span></span>Minimercado con productos regionales</li>
                    <li><span></span>Club House - Spa</li>
                    <li><span></span>Sala de masajes, sauna y jacuzzi</li>
                    <li><span></span>Piscina interior climatizada</li>
                    <li><span></span>Restaurante</li>
                    <li><span></span>Salón de eventos</li>
                    <li><span></span>Sala de conferencias</li>
                </ul>
            </div>
            
            <div className="slides">
                <Carousel>
                    
                    <Carousel.Item><img src={imagenSlide01} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide02} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide03} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide05} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide06} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide07} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide08} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide09} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide10} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide11} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide12} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide13} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide14} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide15} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide16} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide17} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide18} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide19} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide20} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide21} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide22} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide23} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide24} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide25} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide26} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide27} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide28} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide29} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide30} alt="" loading="lazy"></img></Carousel.Item>
                    <Carousel.Item><img src={imagenSlide31} alt="" loading="lazy"></img></Carousel.Item>

                    {/* <Carousel.Item>
                        <div className="video-section">
                            <video>
                                <source src="https://www.youtube.com/watch?v=oQTQVJqRv5k" type="video/mp4"></source>
                            </video>
                        </div>
                    </Carousel.Item> */}
                    
                </Carousel>
            </div>


            <div className="summary">
                <h3>Ubicación Geográfica</h3>
            </div>

            <div className="location">

                    <div className='row '>
                        <div className='col-12'>
                            <div className='mapa'>
                                <iframe title="mapa_ubicacion"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.5129895264163!2d-64.47769698423092!3d-31.344820299931698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x942d64ff1031bd43%3A0x2829ae46a3019256!2sVeneto%20Village%20-%20Club%20Vacacional!5e0!3m2!1ses!2sar!4v1608582576464!5m2!1ses!2sar"
                                frameBorder='0'
                                allowFullScreen=''
                                aria-hidden='false'
                                tabIndex='0'
                                ></iframe>
                            </div>
                        </div>
                </div>
            </div>

        </div>


    </div>
);

export default Product1;
