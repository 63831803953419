import React, { Component } from "react";
import Hero from "../components/Hero";
import Benefits from "../components/Benefits";
import Availables from "../components/Availables";
import Product1 from "../components/Product1";
import Cta1 from "../components/Cta1";
import Product2 from "../components/Product2";
import Cta2 from "../components/Cta2";
// import Slide from "../components/Slide";

class Home extends Component {
  render() {
    return (
      <div>
        <Hero />
        <Benefits />
        <Availables />
        <Product1 />
        <Cta1 />
        <Product2 />
        <Cta2 />
        {/* <Slide /> */}
      </div>
    );
  }
}

export default Home;
