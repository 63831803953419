import React from "react";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHammer, faDraftingCompass, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

// import iconoTerrenosIrregulares from "../assets/statics/icono_terrenos_irregulares.svg";


const Benefits = (props) => (
  <div className="benefits">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 mt-4">
          <h2 className="mb-4">¿Vacaciones, seminarios, trabajo?</h2>
          <h3 className="mb-4 ">Alquileres temporarios en Veneto Edificios</h3>

          <p className="">Alquilá por temporada, por semana, por día</p>
          <h4 className="mb-5"><strong>¡Consultá plazos y combos especiales!</strong></h4>
          <div className="row justify-content-center mt-4">

            <div className="col-md-4 col-lg-4">
              <div className="benefits-item">
                <div className="benefits_item_icon">
                  <FontAwesomeIcon className="rrss_icon"
                    icon={faHammer}
                    size="3x"
                  />
                </div>
                <p className="benefits_item_text">Excelente calidad constructiva</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="benefits-item">
                <div className="benefits_item_icon">
                  <FontAwesomeIcon className="rrss_icon"
                    icon={faDraftingCompass}
                    size="3x"
                  />
                </div>
                <p className="benefits_item_text">Diseño distintivo e innovador</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="benefits-item">
                <div className="benefits_item_icon">
                  <FontAwesomeIcon className="rrss_icon"
                    icon={faMapMarkerAlt}
                    size="3x"
                  />
                </div>
                <p className="benefits_item_text">Ubicación privilegiada</p>
              </div>
            </div>  
          </div>

          <ul className="text-left ml-4">
            <li><span></span>Piscina</li>
            <li><span></span>Salón de usos múltiples</li>
            <li><span></span>Servicio de Limpieza</li>
            <li><span></span>Cocheras amplias</li>
            <li><span></span>Cable</li>
            <li><span></span>WiFi</li>
            <li><span></span>Aire Acondicionado</li>
            <li><span></span>Cocinas equipadas</li>
          </ul>
        </div>
      </div>
      
    </div>
  </div>

);

export default Benefits;
