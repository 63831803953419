import React from 'react';
import Navbar from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'

// import whatsAppBrand from "../assets/statics/whatsapp-brands.svg";

const Tanks =  (props) => (
  <>
  <Navbar/>
  <div className="tanks">
    <div className="container">
      <div className="row justify-content-center">

        <div className="col-md-8 text-center">
          <h1>¡Muchas gracias por contactarte!</h1>
          <h4 className="mt-4">Tu información ya fue asignada a nuestros representantes.</h4>
          <h6 className="my-4">Realmente apreciamos tu interés en completar el formulario y dejarnos tus datos.</h6>
          <p className="my-2 lead">En breve nos pondremos en contacto, a fin de suministrarte más información sobre nuestra propuesta.</p>
          <h5 className="mt-5">¿Interesado en conocer algo mas sobre nosotros?</h5>
          <p className="mt-3">Hacé click en los siguientes enlaces: </p>
          <div className="mt-4">

            <a className="mx-3" href="https://www.facebook.com/constructoradelvalleOficial/" target="_blank" rel="noopener noreferrer" title="Nuestra Facebook Page">
              <FontAwesomeIcon className="rrss_icon"
                icon={faFacebook}
                size="2x"
              />
            </a>

            <a className="mx-3" href="https://www.instagram.com/constructoradelvallesa/" target="_blank" rel="noopener noreferrer" title="Seguinos en Instagram">
              <FontAwesomeIcon className="rrss_icon"
                icon={faInstagram}
                size="2x"
              />
            </a>

            <a className="mx-3" href="https://www.constructoradelvalle.com/" target="_blank" rel="noopener noreferrer" title="Visita nuestra web">
              <FontAwesomeIcon className="rrss_icon"
                icon={faGlobe}
                size="2x"
              />
            </a>

            <a className="mx-3" href="&#109;ailto&#58;co%&#54;&#69;&#116;a&#99;to&#64;const&#37;72uct&#111;%&#55;&#50;a%64elv%61lle%2Ec&#37;6Fm" target="_blank" rel="noopener noreferrer" title="Envíanos un email">
              <FontAwesomeIcon className="rrss_icon"
                icon={faEnvelope}
                size="2x"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
)

export default Tanks;